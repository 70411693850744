






































































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniModal from '@/components/layout/SygniModal.vue'
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniLine from '@/components/layout/SygniLine.vue';
import { AutentiParticipant } from '../store/types';
import { Prop } from 'vue-property-decorator';
import { email, numeric, required, requiredIf } from 'vuelidate/lib/validators';
import _ from 'lodash';

Component.registerHooks(['validations'])
@Component({
  components: { SygniModal, SygniLine, SygniToggleSwitch, SygniSelect, SygniCheckbox, SygniInput, SygniInputError, SygniContainerTitle },
})
export default class ParticipantModal extends Vue {
  @Prop({ default: false }) isLoading: boolean
  @Prop({ default: null }) dictionaries: any
  @Prop({ default: 0 }) participantsLength: number
  @Prop({ default: null }) participantData: AutentiParticipant
  @Prop({ default: 'single' }) mode: 'global' | 'single'
  @Prop({ default: 'add' }) type: 'add' | 'edit'

  participant: AutentiParticipant = {
    companyRepresentative: false,
    taxPrefix: 'PL',
    taxNumber: null,
    position: null,
    companyName: null,
    firstName: null,
    surname: null,
    email: null,
    role: null,
    signatureType: null,
    authorizationViaSmsCode: false,
    secureAccessToTheDocumentWithSmsCode: false,
    zipFileSecuredWithAnSmsCode: false,
    mobilePhone: null,
  }

  get header() {
    return `${this.type === 'add' ? 'Add' : 'Edit'} participant`
  }

  get confirmText() {
    return this.type === 'add' ? 'Add' : 'Update'
  }

  get showMobile() {
    return (this.participant?.authorizationViaSmsCode || this.participant?.secureAccessToTheDocumentWithSmsCode || this.participant?.zipFileSecuredWithAnSmsCode)
  }

  get roles() {
    return this.dictionaries?.roles?.map((el: any) => {
      el.label = this.$options.filters.snakeCaseToTitleCase(el.label?.toUpperCase());
      el.label = this.$options.filters.capitalizeFirstLetter(el.label);
      return el
    })
  }

  get signatureTypes() {
    const signatureTypes = this.dictionaries?.signatureTypes?.map((el: any) => {
      el.label = this.getSignatureTypeLabel(el.value)
      return el
    })

    return signatureTypes || []
  }

  get countryCodes() {
    const countryCodes = this.$store.getters['dictionary/globalSelects']?.countries?.map((el: any) => {
      return { label: el.value, value: el.value }
    })

    return countryCodes
  }

  get taxNumberValidation() {
    if (this.$v?.participant?.taxNumber?.$error) {
      return this.$v?.participant?.taxNumber
    }

    return this.$v?.participant?.taxPrefix
  }

  getSignatureTypeLabel(value: string) {
    switch (value) {
      case 'SIGNATURE_PROVIDER-SIGNATURE_TYPE:BASIC':
        return 'E-signature Autenti'
      case 'SIGNATURE_PROVIDER-SIGNATURE_TYPE:ADVANCED':
        return 'Advanced E-signature Autenti'
      case 'SIGNATURE_PROVIDER-SIGNATURE_TYPE:QUALIFIED':
        return 'Qualified signature'
      default:
        return value
    }
  }

  buildRequestPayload() {
    const payload = _.cloneDeep(this.participant)

    if (this.type === 'add') {
      if (this.participantsLength) {
        payload.priority = Number(this.participantsLength) + 1
      }

      return payload
    }

    const id = payload?.id

    delete payload?.id

    return {
      id,
      data: payload
    }
  }

  async handleConfirm() {
    this.$v?.$touch();

    if (!this.$v?.$error) {
      console.log('handle action', this.type, this.mode)

      this.isLoading = true

      try {
        const payload = this.buildRequestPayload()
        const actionName = this.type === 'add' ? 'templates/addGlobalParticipant' : 'templates/editGlobalParticipant'

        await this.$store.dispatch(actionName, payload)
        this.$notify({
          duration: 3000,
          type: 'success',
          title: 'Success',
          text: `Participant ${this.type === 'add' ? 'added' : 'updated'}!`
        })
        this.$emit('confirm')
      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e)
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        })
      }

      this.isLoading = false
    }
  }

  mounted() {
    if (this.type === 'add') {
      // if (this.signatureTypes?.length === 1) {
      //   this.participant.signatureType = this.signatureTypes[0]?.value
      // }
      
      // if (this.roles?.length === 1) {
      //   this.participant.role = this.roles[0]?.value
      // }
    }

    if (this.type === 'edit') {
      this.participant = _.cloneDeep(this.participantData)
    }
  }

  get validationObject() {
    return {
      participant: {
        // company fields
        companyRepresentative: {},
        taxPrefix: {
          required: requiredIf(() => this.participant?.companyRepresentative)
        },
        taxNumber: {
          required: requiredIf(() => this.participant?.companyRepresentative),
          numeric
        },
        position: {
          required: requiredIf(() => this.participant?.companyRepresentative)
        },
        companyName: {
          required: requiredIf(() => this.participant?.companyRepresentative)
        },
        // rest of fields
        firstName: { required },
        surname: { required },
        email: { required, email },
        role: { required },
        signatureType: { required },
        authorizationViaSmsCode: {},
        secureAccessToTheDocumentWithSmsCode: {},
        zipFileSecuredWithAnSmsCode: {},
        mobilePhone: { 
          required: requiredIf(() => this.participant?.authorizationViaSmsCode || this.participant?.secureAccessToTheDocumentWithSmsCode || this.participant?.zipFileSecuredWithAnSmsCode) 
        }
      }
    }
  }

  validations() {
    return this.validationObject
  }
}
