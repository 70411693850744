var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "participants-table"
  }, [_c('simplebar', {
    ref: "tableInner",
    staticClass: "participants-table__inner",
    attrs: {
      "data-simplebar-auto-hide": "false"
    }
  }, [_c('table', {
    staticClass: "table b-table sygni-b-inner-table sygni-b-table",
    attrs: {
      "innertable": "true"
    }
  }, [_c('thead', [_c('tr', _vm._l(_vm.tableFields, function (field) {
    return _c('th', {
      key: field === null || field === void 0 ? void 0 : field.key,
      class: field === null || field === void 0 ? void 0 : field.class
    }, [_c('div', {
      staticClass: "text-left"
    }, [_vm._v(_vm._s(field === null || field === void 0 ? void 0 : field.label))])]);
  }), 0)]), _c('draggable', {
    attrs: {
      "tag": "tbody",
      "draggable": ".row-drag",
      "handle": ".handle",
      "options": {
        disabled: !_vm.participationPriority
      }
    },
    model: {
      value: _vm.tableItems,
      callback: function callback($$v) {
        _vm.tableItems = $$v;
      },
      expression: "tableItems"
    }
  }, _vm._l(_vm.tableItems, function (row, index) {
    return _c('tr', {
      key: "row-".concat(index),
      staticClass: "row-drag"
    }, _vm._l(_vm.tableFields, function (field) {
      return _c('td', {
        key: field === null || field === void 0 ? void 0 : field.key
      }, [(field === null || field === void 0 ? void 0 : field.key) === 'handle' ? [_c('div', {
        staticClass: "handle"
      })] : _vm._e(), (field === null || field === void 0 ? void 0 : field.key) === 'priority' ? [_c('div', {
        staticClass: "text-nowrap text-left"
      }, [_vm._v(_vm._s(index + 1))])] : _vm._e(), (field === null || field === void 0 ? void 0 : field.key) === 'actions' ? [_c('div', {
        staticClass: "actions-group"
      }, [_c('div', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          value: {
            title: 'Edit participant',
            delay: 0,
            interactive: false
          },
          expression: "{ title: 'Edit participant', delay: 0, interactive: false }",
          modifiers: {
            "hover": true
          }
        }],
        staticClass: "action",
        on: {
          "click": function click($event) {
            return _vm.$emit('editParticipant', row);
          }
        }
      }, [_c('img', {
        attrs: {
          "src": _vm._f("getIcon")('EDIT'),
          "alt": ""
        }
      })]), _c('div', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          value: {
            title: 'Delete participant',
            delay: 0,
            interactive: false
          },
          expression: "{ title: 'Delete participant', delay: 0, interactive: false }",
          modifiers: {
            "hover": true
          }
        }],
        staticClass: "action",
        on: {
          "click": function click($event) {
            return _vm.$emit('deleteParticipant', row === null || row === void 0 ? void 0 : row.id);
          }
        }
      }, [_c('img', {
        attrs: {
          "src": _vm._f("getIcon")('DELETE'),
          "alt": ""
        }
      })])])] : _vm._e(), (field === null || field === void 0 ? void 0 : field.key) === 'name' ? [_c('div', {
        staticClass: "text-nowrap text-left"
      }, [_vm._v(" " + _vm._s(row === null || row === void 0 ? void 0 : row.firstName) + " " + _vm._s(row === null || row === void 0 ? void 0 : row.surname) + " ")])] : _vm._e(), !((field === null || field === void 0 ? void 0 : field.key) === 'actions' || (field === null || field === void 0 ? void 0 : field.key) === 'priority' || (field === null || field === void 0 ? void 0 : field.key) === 'handle' || (field === null || field === void 0 ? void 0 : field.key) === 'name') ? [_c('div', {
        staticClass: "text-nowrap text-left"
      }, [_vm._v(" " + _vm._s(_vm.formatField(row[field === null || field === void 0 ? void 0 : field.key], field === null || field === void 0 ? void 0 : field.key)) + " ")])] : _vm._e()], 2);
    }), 0);
  }), 0)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }