























































import Vue from 'vue'
import Component from 'vue-class-component'
import EditorComponent from '@/modules/genprox/components/wysiwyg/EditorComponent.vue';
import ParticipantsTable from '@/modules/genprox/modules/fund/modules/capital-rise/modules/templates/components/ParticipantsTable.vue';
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniMultiSelect from "@/components/inputs/SygniMultiSelect.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniModal from '@/components/layout/SygniModal.vue';
import ParticipantModal from './ParticipantModal.vue';
import { Prop } from 'vue-property-decorator';
import { AutentiConfiguration, AutentiParticipant, AutentiTag } from '../store/types';
import { required } from 'vuelidate/lib/validators';
import _ from 'lodash';

Component.registerHooks(['validations'])
@Component({
  components: { ParticipantModal, EditorComponent, ParticipantsTable, SygniRoundedButton, SygniRectButton, SygniContainerTitle, SygniLoader, SygniToggleSwitch, SygniModal, SygniMultiSelect, SygniSelect, SygniInput }
})
export default class AutentiForm extends Vue {
  @Prop({ default: 'single' }) mode: 'single' | 'global'
  @Prop({ default: null }) showSaveButton: boolean;

  participantModal: { show: boolean, participantData: AutentiParticipant | null, mode: 'add' | 'edit' } = {
    show: false,
    mode: 'add',
    participantData: null
  }

  dictionaries: any = null;
  isLoading: boolean = false;
  autentiTemplates: any[] = [];
  selectedTags: string[] = [];

  deletingParticipantId: string = null

  configuration: AutentiConfiguration = {
    documentName: null,
    tags: [],
    signatureModel: null,
    language: null,
    messageToParticipants: null,
    active: false,
    participationPriority: false,
    participant: [],
  }

  get isGlobal() {
    return this.mode === 'global'
  }

  get languages(): any[] {
    return this.dictionaries?.languages || []
  }

  get signatureModels(): any[] {
    return this.dictionaries?.signatureModels || []
  }

  get tags() {
    return this.dictionaries?.tags?.map((el: AutentiTag) => {
      return { label: el?.description || '', value: el?.id }
    }) || []
  }

  openDeletingParticipantModal(id: string) {
    console.log('open deleting modal', id)
    this.deletingParticipantId = id
  }

  closeDeleteConfirmationModal() {
    this.deletingParticipantId = null
  }

  confirmParticipantModal() {
    this.loadGlobalConfig();
    this.closeParticipantModal();
  }

  openParticipantModal(mode: 'add' | 'edit' = 'add', participantData: AutentiParticipant = null) {
    this.participantModal.mode = mode
    this.participantModal.participantData = participantData
    this.participantModal.show = true
  }

  closeParticipantModal() {
    this.participantModal.participantData = null
    this.participantModal.mode = 'add'
    this.participantModal.show = false
  }

  handleMessageUpdate(content: string) {
    this.configuration.messageToParticipants = content
  }

  updateTags(tags: string[]) {
    const selectedTags = tags?.map((id: string) => {
      return this.dictionaries?.tags?.find((tag: AutentiTag) => tag.id === id)
    })
    this.configuration.tags = selectedTags
  }

  toggleAllTags() {
    if (this.selectedTags?.length < this.tags?.length) {
      const tags = this.tags?.map((el: any) => el.value);
      this.selectedTags = tags;
      this.updateTags(tags);
    } else {
      this.selectedTags = [];
      this.updateTags([]);
    }
  }

  async loadDictionaries() {
    this.dictionaries = await this.$store.dispatch('templates/getAutentiDictionaries')

    if (this.dictionaries?.signatureModels && this.dictionaries?.signatureModels?.length == 1) {
      this.configuration.signatureModel = this.dictionaries?.signatureModels[0]?.value || null
    }

    if (this.dictionaries?.languages) {
      const defaultLanguage = this.dictionaries?.languages?.find((el: any) => el.value?.toLowerCase() === 'pl')
      this.configuration.language = defaultLanguage.value
    }
  }

  async loadGlobalConfig() {
    const globalConfig = await this.$store.dispatch('templates/getAutentiGlobalConfig')

    if (globalConfig?.active !== undefined) {
      if (globalConfig?.tags?.length) {
        this.selectedTags = globalConfig?.tags?.map((tag: AutentiTag) => tag.id)
      }

      this.configuration = { ...globalConfig }
    }
  }

  async deleteParticipant() {
    if (this.deletingParticipantId) {
      this.isLoading = true
      try {
        await this.$store.dispatch('templates/deleteGlobalParticipant', this.deletingParticipantId)
        await this.loadGlobalConfig();
        
        this.$notify({
          duration: 3000,
          type: 'success',
          title: 'Success',
          text: 'Participant deleted'
        })

      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e)
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        })
      }
      this.isLoading = false
    }

    this.closeDeleteConfirmationModal()
  }

  getRequestPayload() {
    const payload: AutentiConfiguration = _.cloneDeep(this.configuration);

    if (payload.participant?.length) {
      payload.participant = payload.participant?.map((participant: AutentiParticipant, index: number) => {
        participant.priority = index + 1

        return participant
      })
    }

    return payload
  }

  async handleSubmit() {
    this.$v?.$touch();

    if (!this.$v?.$error) {
      this.isLoading = true;
      console.log('handle submit action for mode: ', this.mode)

      if (this.isGlobal) {
        try {
          const requestPayload = this.getRequestPayload()
          await this.$store.dispatch('templates/saveAutentiGlobalConfig', requestPayload)
          await this.loadGlobalConfig()
          this.$notify({
            duration: 2500,
            type: 'success',
            title: 'Success',
            text: 'Configuration saved!'
          })
        } catch (e) {
          const errorMessage = this.$options.filters.errorHandler(e)
          this.$notify({
            duration: 2500,
            type: 'error',
            title: 'Error',
            text: errorMessage
          })
        }
      }

      this.isLoading = false;
    }
  }

  async mounted() {
    this.isLoading = true
    
    try {
      this.loadDictionaries()
      await this.loadGlobalConfig()
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isLoading = false
  }

  get validationObject() {
    if (this.configuration?.active) {
      return {
        configuration: {
          documentName: !this.isGlobal ? { required } : {},
          tags: !this.isGlobal ? { required } : {},
          signatureModel: { required },
          language: { required },
        }
      }
    }

    return {}
  }

  validations() {
    return this.validationObject
  }
}
